import React, {useEffect, useState} from 'react';
import classes from "./HbPopUp.module.scss";
import HbButton from "../HbButton/HbButton";

const HbPopUp = ({isVisible, setIsVisible, text, qrLink = ""}) => {
    // const [isVisible, setIsVisible] = useState(false);
    const [popupContainerClasses, setPopupContainerClasses] = useState([classes.popup_container]);

    useEffect(() => {
        if (isVisible) {
            setPopupContainerClasses([classes.popup_container]);
        }else {
            setPopupContainerClasses([classes.popup_container, classes.popup_container_hidden]);
        }
    }, [isVisible]);

    return (
        <div className={popupContainerClasses.join(" ")}>
            <div className={`container`}>
                <div className={classes.popup_block}>
                    {text}

                    {
                        qrLink !== "" && <a href={qrLink}>Ссылка на оплату</a>
                    }

                    <HbButton
                        text={"Ok"}
                        onClick={setIsVisible}
                    />
                </div>
            </div>
        </div>
    );
};

export default HbPopUp;