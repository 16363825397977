import {httpAxios} from "./HttpAxios";

export default class LifePayService {
    static async createBill(productTitle, phone, email, payment_method, amount) {
        // try {
            const csrf = await httpAxios.get('/sanctum/csrf-cookie');

            const response = await httpAxios.post("/lifepay/create_bill", {
                product_title: productTitle,
                phone: phone,
                email: email,
                payment_method: payment_method,
                amount: amount
            });
            
            return response;
        // } catch (e) {
        //     console.log(e);
        // }
    }
}