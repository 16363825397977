import React, {useEffect, useState} from 'react';
import classes from "./HbInput.module.scss";

const HbInput = ({type, className, error, mask, onChange}) => {
    const [inputClasses, setInputClasses] = useState([classes.hb_input, className]);

    useEffect(() => {
        if (type == "tel") {
            setInputClasses([classes.hb_input_tel, className])
        }
    }, [type]);

    return (
        <div className={classes.hb_input_container}>
            <input
                type={type}
                className={inputClasses.join(" ")}
                onChange={onChange}
            />
            {
                type === 'tel'
                    ?
                    <div className={classes.tel_code}>
                        +7
                    </div>
                    : ''
            }

            <div className={classes.input_error_container}>
                {error}
            </div>
        </div>
    );
};

export default HbInput;