import {createBrowserRouter} from "react-router-dom";
import React from "react";
import App from "./App";
import MainLayout from "./components/layouts/MainLayout/MainLayout";
import SendPayment from "./components/pages/payments/SendPayment/SendPayment";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout/>,
        children: [
            // Main page
            {
                path: "/",
                element: <App/>
            },

            // Send payment to client with phone/email
            {
                path: "/payment/send",
                element: <SendPayment/>
            }
        ]
    },
]);

export default router;