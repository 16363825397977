import React from 'react';
import classes from "./MainHeader.module.scss";

const MainHeader = () => {
    return (
        <div>
            <header className={classes.header}>
                BIG HIDDEN MANAGER
            </header>
        </div>
    );
};

export default MainHeader;