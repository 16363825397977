import React from 'react';
import MainHeader from "../MainHeader/MainHeader";
import MainFooter from "../MainFooter/MainFooter";
import {Outlet} from "react-router-dom";

const MainLayout = () => {
    return (
        <div>
            <MainHeader/>
            <Outlet/>
            <MainFooter/>
        </div>
    );
};

export default MainLayout;