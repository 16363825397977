import axios from "axios";

export const baseURL = 'https://bitrix.hidden-burg.com';

export const httpAxios = axios.create({
    baseURL: baseURL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
});

// export const csrf = await httpAxios.get('/sanctum/csrf-cookie');
