import React, {useState} from 'react';

const UseFetching = (callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching = async () => {
        try {
            setIsLoading(true);
            setError("");
            await callback();
        } catch (e) {
            // if (e.response.data.message !== '') {
            //     setError(e.response.data.message);
            // }else {
            //     setError(e.message);
            // }
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    return [fetching, isLoading, error];
};

export default UseFetching;