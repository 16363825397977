import React, {useEffect, useRef, useState} from 'react';
import classes from "./SendPayment.module.scss";
import {useSearchParams} from "react-router-dom";
import HbInput from "../../../UI/HbInput/HbInput";
import HbSelect from "../../../UI/HbSelect/HbSelect";
import HbButton from "../../../UI/HbButton/HbButton";
import LifePayService from "../../../../API/LifePayService";
import useFetching from "../../../../hooks/useFetching";
import HbPopUp from "../../../UI/HbPopUp/HbPopUp";

const SendPayment = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [dealId, setDealId] = useState("");
    const [productCode, setProductCode] = useState("");
    const [productTitle, setProductTitle] = useState("");
    // const [personCount, setPersonCount] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [amount, setAmount] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const paymentMethods = {sbp: "СБП"};
    // const paymentMethods = {sbp: "СБП", cash: "Наличные"};
    const [errors, setErrors] = useState("");
    const [lifepayError, setLifepayError] = useState("");
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [qrLink, setQrLink] = useState("");

    // Get select by selector
    const getSelect = (method) => {
        setPaymentMethod(method);
    }

    // Create bill by click btn
    const [createBill, isCreateBillLoading, createBillError] = useFetching(async () => {
        setLifepayError("");
        const response = await LifePayService.createBill(productTitle, phone, email, paymentMethod, amount);

        if (response.status === 200) {
            if (response.data !== undefined && response.data.code === 0 && response.data.data !== undefined) {
                setIsPopUpVisible(true);
                setQrLink(response.data.data.paymentUrl)
            }else if(response.data !== undefined && response.data.code !== 0) {
                setLifepayError(response.data.message)
            }
        }
        console.log(response);
    });


    useEffect(() => {
        if (createBillError !== "") {
            setErrors(createBillError.response.data.errors);
        }else {
            setErrors("");
        }
    }, [createBillError]);

    // const createBill = async () => {
    //     const response = await LifePayService.createBill(productTitle, phone, email, paymentMethod, amount);
    //
    //     console.log(response);
    // }

    useEffect(() => {
        if (searchParams) {
            console.log(searchParams.get("deal_id"));
            console.log(searchParams.get("product_code"));
            console.log(searchParams.get("product_title"));
            console.log(decodeURI(searchParams.get("product_title")));
            // console.log(searchParams.get("person_count"));
            console.log(searchParams.get("amount"));

            setDealId(searchParams.get("deal_id"));
            setProductCode(searchParams.get("product_code"));
            // setPersonCount(searchParams.get("person_count"));
            setAmount(searchParams.get("amount"));
        }
    }, [searchParams]);

    useEffect(() => {
        setProductTitle(`${dealId} ` + decodeURI(searchParams.get("product_title")));
    }, [dealId])

    useEffect(() => {
        console.log(phone)
        console.log(email)
        console.log(paymentMethod)
    }, [phone, email, paymentMethod])

    const closePopUp = () => {
        setIsPopUpVisible(false);
    }

    return (
        <div className={"container"}>
            <HbPopUp
                isVisible={isPopUpVisible}
                setIsVisible={closePopUp}
                text={`Ссылка отправлена клиенту!`}
                qrLink={qrLink}
            />

            <h2 className={classes.title_container}>
                Выставить счёт клиенту
            </h2>

            {/* Deal id, short product title, full product title, person count, amount */}
            <div className={classes.payment_description_container}>
                <div>
                    Номер сделки: <b>{dealId}</b>
                </div>

                <div>
                    Код товара: <b>{productCode}</b>
                </div>

                <div>
                    Описание товара: <b>{productTitle}</b>
                </div>

                {/*<div>*/}
                {/*    Кол-во человек: {personCount}*/}
                {/*</div>*/}

                <div>
                    Сумма: <b>{amount} руб.</b>
                </div>
            </div>

            <hr/>

            {/* Input data for payment (phone/email), payment method, GID PIN code */}
            <div className={classes.payment_data_container}>
                <div>
                    <div className={classes.input_title}>
                        Введите телефон:
                    </div>

                    <HbInput
                        type={"tel"}
                        onChange={(e) => setPhone(e.target.value !== "" ? "7" + e.target.value : "")}
                        error={errors !== "" && errors.phone !== undefined && errors.phone[0]}
                    />
                </div>

                <div className={classes.and_or_container}>
                    и/или
                </div>

                <div>
                    <div className={classes.input_title}>
                        Введите Email:
                    </div>

                    <HbInput
                        type={"email"}
                        onChange={(e) => setEmail(e.target.value)}
                        error={errors !== "" && errors.email !== undefined && errors.email[0]}
                    />
                </div>

                {/*<div className={classes.payment_method_container}>*/}
                {/*    <div className={classes.payment_method_title}>*/}
                {/*        Выберите способ оплаты:*/}
                {/*    </div>*/}

                {/*    <HbSelect*/}
                {/*        type={"selector"}*/}
                {/*        options={paymentMethods}*/}
                {/*        getSelect={getSelect}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className={classes.button_container}>
                    <HbButton
                        text={"Выслать счёт"}
                        onClick={createBill}
                        isLoading={isCreateBillLoading}
                        error={lifepayError !== "" && lifepayError}
                    />
                </div>
            </div>

            {/* List of previous payments */}
            <div className={classes.previous_payments_container}>

            </div>
        </div>
    );
};

export default SendPayment;