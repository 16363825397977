import React from 'react';
import classes from "./Preloader.module.scss";

const Preloader = () => {
    return (
        <div className={classes.preloader_container}>
            <div className={classes.preloader_block}>

            </div>
        </div>
    );
};

export default Preloader;