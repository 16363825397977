import React, {useEffect, useState} from 'react';
import classes from "./HbButton.module.scss";
import Preloader from "../Preloader/Preloader";

const HbButton = ({text, className, onClick, isLoading = false, error = ""}) => {
    const [buttonClasses, setButtonClasses] = useState([classes.hb_button]);

    useEffect(() => {
        if (isLoading) {
            setButtonClasses([classes.hb_button, className]);
        }else {
            setButtonClasses([classes.hb_button, className]);
        }
    }, [className, isLoading]);

    useEffect(() => {
        console.log(error)
    }, [error]);

    return (
        <div>
            <button
                disabled={isLoading}
                className={buttonClasses.join(" ")}
                onClick={onClick}
            >
                {
                    isLoading
                    ?
                        <Preloader
                            classname={classes.preloader}
                        />
                    :
                        text
                }
            </button>

            <div className={classes.hb_button_error}>
                {error}
            </div>
        </div>
    );
};

export default HbButton;